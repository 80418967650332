import { dvh } from '@graphcommerce/framer-utils'
import { useFabSize } from '@graphcommerce/next-ui'
import { GlobalStyles } from '@mui/material'

export const CustomerServiceChat = () => {
  const fabIconSize = useFabSize('responsive')

  // previously, we used to load the zendesk chat widget scripttag in this component. Which we've now moved to GTM for easier management.
  // these styles still need to be applied though if the widget is enabled
  return (
    <GlobalStyles
      styles={{
        'body:has(.LayoutOverlayBase-backdrop[style*="opacity: 1"]) iframe[data-product="web_widget"] ~ div > div > iframe':
          {
            display: 'none !important',
          },
        '& iframe[data-product="web_widget"] ~ div > div > iframe': {
          zIndex: '1 !important',
        },
        '& iframe[data-product="web_widget"] ~ div > div:nth-of-type(1) > iframe': {
          right: '20px !important',

          '@media (max-width: 960px)': {
            right: '0 !important',
            bottom: `calc(40px + ${fabIconSize}) !important`,
            maxHeight: `calc(${dvh(100)} - 150px) !important`,
          },
        },
        '& iframe[data-product="web_widget"] ~ div > div:nth-of-type(2) > iframe': {
          transform: `scale(${50 / 64}) !important`,

          '@media (max-width: 960px)': {
            bottom: '-3px !important',
            right: '65px !important',
            transform: `scale(0.63) !important`,
          },
        },
      }}
    />
  )
}
