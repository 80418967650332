/* eslint-disable react/no-danger */
/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useStoreData } from '../../lib/hooks/useStoreData'

export function CookieFirstScript() {
  const { mode } = useStoreData()
  const endpoint = import.meta.graphCommerce.magentoEndpoint

  if (mode === 'b2b' || endpoint.includes('rc.vaessen-creative.com')) return null

  return (
    <>
      {/* The below script tag can not be loaded aynschronously as autoblocking will no longer work */}
      <script
        id='cookie-first'
        src='https://consent.cookiefirst.com/sites/vaessen-creative.com-773d54d7-dfff-419c-872f-bd4af89ae5be/consent.js'
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          function updateAutoBlockList() {
            const { autoblock } = window.__COOKIE_BANNER_SETTINGS__;
            return autoblock.filter((item) => !item.urls.some((url) => url.includes('maps.googleapis.com')));
          }
          window.__COOKIE_BANNER_SETTINGS__ = {
            ...window.__COOKIE_BANNER_SETTINGS__,
            autoblock: updateAutoBlockList()
          };
        `,
        }}
      />
    </>
  )
}
